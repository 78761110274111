import { loadScript } from './common';
import lj from './lumberjack';

//For reference: https://developers.google.com/youtube/iframe_api_reference#Getting_Started

let ytVideo = document.querySelectorAll('.youtube-video');
lj.setupLJ();

let onYouTubeIframeAPIReadyCallbacks = [];

const fireLJOnYoutubeStateChange = (eventName, status, duration) => {
  lj.pushEvents({
    event_name: eventName,
    event_type: 'viewed',
    properties: {
      status: status,
      currentDuration: duration
    }
  });
};

for (let i = 0; i < ytVideo.length; i++) {
  let iframe = ytVideo[i].querySelector('iframe');
  iframe.onload = function() {
    let ljEventName = ytVideo[i].dataset.lumberjackAction;

    var ytURL = 'https://www.youtube.com/iframe_api';
    var ytSrc = document.createElement('script');
    ytSrc.src = ytURL;

    loadScript(ytURL, ytSrc);

    let onPlayerStateChange = function(event) {
      const currentDuration = player.getCurrentTime();
      let status;
      if (event.data === YT.PlayerState.ENDED) {
        ytVideo[i].classList.add('ended');
        status = 'ended';
      } else if (event.data === YT.PlayerState.PAUSED) {
        ytVideo[i].classList.add('paused');
        status = 'paused';
      } else if (event.data === YT.PlayerState.PLAYING) {
        ytVideo[i].classList.remove('ended');
        ytVideo[i].classList.remove('paused');
        status = 'playing';
      }
      if (status && ljEventName) {
        fireLJOnYoutubeStateChange(ljEventName, status, currentDuration);
      }
    };

    let player;

    onYouTubeIframeAPIReadyCallbacks.push(function() {
      player = new YT.Player(iframe, {
        events: {
          onStateChange: onPlayerStateChange
        }
      });
    });

    ytVideo[i].addEventListener('click', function() {
      let playerState = player.getPlayerState();
      if (playerState === YT.PlayerState.ENDED) {
        player.seekTo(0);
      } else if (playerState === YT.PlayerState.PAUSED) {
        player.playVideo();
      }
    });
    window.onYouTubeIframeAPIReady = function() {
      for (let i = 0; i < onYouTubeIframeAPIReadyCallbacks.length; i++) {
        onYouTubeIframeAPIReadyCallbacks[i]();
      }
    };
  };
}
