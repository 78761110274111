/**
 * Check whether a particular script is loaded else load it.
 * @param scriptURL
 * @param scriptSrc
 */
export function loadScript(scriptURL, scriptSrc) {
  if (document.scripts) {
    var found = false;
    Array.from(document.scripts).forEach(function(script) {
      if (script.getAttribute('src') === scriptURL) {
        found = true;
      }
    });
    if (!found) {
      document.documentElement.appendChild(scriptSrc);
    }
  }
}
